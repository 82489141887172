<template>
  <div class="visibility-project-setting">
    <div class="header">
      <div class="titre-page">
        <h4>Gestion des visibilités des projets</h4>
      </div>
      <div class="button-add-filter">
        <b-button size="sm" variant="success" @click="addNouveauFilter">
          <font-awesome-icon class="" icon="plus" />
          Ajouter un filtre
        </b-button>
      </div>
    </div>
    <hr />
    <div v-if="initLoading" class="three-dots-loading">
      Chargement en cours
    </div>
    <b-row class="content-page" v-else>
      <b-col class="filter" col xs="12" sm="6" md="4">
        <h4>Filtres</h4>
        <div
          class="list-filter"
          v-if="getFiltersProject && getFiltersProject.length"
        >
          <b-list-group>
            <b-list-group-item
              button
              v-for="filter in getFiltersProject"
              :key="filter.id"
              @click="handleClickfilter(filter)"
            >
              <div class="content-filter">
                <div class="name-filter">
                  <div
                    class="icon-check"
                    v-if="selectedFilter && selectedFilter.id == filter.id"
                  >
                    <font-awesome-icon icon="check" />
                  </div>
                  <div class="capitalize">
                    {{ filter.name }}
                  </div>
                </div>
                <div class="buttons-filter">
                  <b-dropdown
                    class="dropDown-filter"
                    size="lg"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    text="..."
                  >
                    <b-dropdown-item @click.stop="handleUpdateClick(filter)">
                      <font-awesome-icon
                        class="iconDropDown"
                        icon="pencil-alt"
                      />
                      Modifier
                    </b-dropdown-item>
                    <b-dropdown-item @click.stop="handleDeleteClick(filter)">
                      <font-awesome-icon
                        class="iconDropDown"
                        icon="trash-alt"
                      />
                      Supprimer</b-dropdown-item
                    >
                  </b-dropdown>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-else class="not-filter">Aucun Filters</div>
      </b-col>
      <b-col class="users" col xs="12" sm="6" md="7">
        <h4>Utilisateurs</h4>
        <div class="bloc-select-multi" v-if="selectedFilter">
          <multiselect
            v-model="selectedUsers"
            :options="getUsers"
            placeholder="Rechercher l'utilisateur"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            label="full_name"
            track-by="id"
            :preselect-first="false"
            :loading="getProjectListUsersLoading"
          >
          </multiselect>
          <div class="postion-btn" v-if="selectedUsers && selectedUsers.length">
            <b-button
              class="button-add-users"
              variant="success"
              @click="handelClickUsersToFilter(selectedUsers)"
              >Enregistrer</b-button
            >
          </div>
        </div>
        <div
          class="three-dots-loading margin"
          v-if="gettVisibilityProjectLoading && selectedFilter"
        >
          Chargement en cours
        </div>
        <div
          v-if="
            getProjectUsersLoading ||
              getProjectDeleteUsersLoading ||
              getProjectChangeUsersLoading
          "
          class="three-dots-loading margin"
        >
          Chargement en cours
        </div>
        <div
          class="addListUsers"
          v-if="!gettVisibilityProjectLoading && selectedFilter"
        >
          <b-list-group>
            <b-list-group-item
              v-for="user in selectedFilter.users"
              :key="user.id"
            >
              <div class="user">
                <div>
                  {{ user.full_name }}<br /><span class="email-user">{{
                    user.email
                  }}</span>
                </div>
                <div class="block-action">
                  <b-dropdown
                    text="Changer filtre"
                    class="dropDown-Users"
                    v-if="computedFilter && computedFilter.length"
                  >
                    <b-dropdown-item
                      v-for="filter in computedFilter"
                      :key="'filter' + filter.id"
                      @click="handleupdateFilterUsersClick(filter, user)"
                      class="capitalize"
                      >{{ filter.name }}</b-dropdown-item
                    >
                  </b-dropdown>
                  <div
                    class="delete-user"
                    @click.stop="handleDeleteClick(user)"
                    title="Supprimer"
                  >
                    X
                  </div>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
          <div
            class="message"
            v-if="
              !getProjectUsersLoading &&
                !getProjectDeleteUsersLoading &&
                selectedFilter.users.length == 0
            "
          >
            Aucun utilisateur
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Ajouter Filter -->
    <b-modal
      no-close-on-backdrop
      id="add_Filter"
      ref="add_Filter"
      title="Ajouter un filtre"
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal()"
      size="lg"
    >
      <form @submit.prevent="ajouterFilter">
        <FilterProjet
          :fields="getFieldsToFiltre"
          v-model="conditions"
        ></FilterProjet>
        <b-form-group label="Nom du filtre">
          <b-form-input v-model="name"></b-form-input>
        </b-form-group>
        <div class="message">
          <div v-if="gettVisibilityProjectLoading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
          <div v-if="getVisibilityProjectError" class="error">
            <ul v-if="Array.isArray(getVisibilityProjectError)">
              <li v-for="(e, index) in getVisibilityProjectError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getVisibilityProjectError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('add_Filter')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- Supprimer Filter -->
    <b-modal
      no-close-on-backdrop
      ref="ModalDeleteFilter"
      id="ModalDeleteFilter"
      :title="
        filterToDelete && filterToDelete.conditions
          ? 'Supprimer un filtre'
          : 'Supprimer un utilisateur'
      "
      :hide-footer="true"
    >
      <p>
        Êtes-vous certain de vouloir supprimer
        <span v-if="filterToDelete && filterToDelete.conditions">
          le filtre
          <strong class="capitalize">
            {{ filterToDelete ? filterToDelete.name : '' }}</strong
          >
          ?
        </span>
        <span v-else>
          l'utilisateur
          <strong> {{ filterToDelete ? filterToDelete.full_name : '' }}</strong>
          ?
        </span>
      </p>
      <div class="message">
        <div v-if="gettVisibilityProjectLoading" class="three-dots-loading">
          Chargement en cours
        </div>
        <div v-if="getVisibilityProjectError" class="error">
          <ul v-if="Array.isArray(getVisibilityProjectError)">
            <li v-for="(e, index) in getVisibilityProjectError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getVisibilityProjectError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('ModalDeleteFilter')"
          variant="outline-secondary"
        >
          Annuler
        </b-button>
        <b-button @click="handleDeleteClickFilter" variant="success">
          Valider
        </b-button>
      </div>
    </b-modal>
    <!-- Modifier Filter -->
    <b-modal
      no-close-on-backdrop
      id="update_Filter"
      ref="update_Filter"
      title="Modifier un filtre"
      :hide-footer="true"
      size="lg"
    >
      <form v-if="filterToUpdate" @submit.prevent="handleUpdateClickFilter">
        <FilterProjet
          :fields="getFieldsToFiltre"
          v-model="filterToUpdate.conditions"
        ></FilterProjet>
        <b-form-group label="Nom du filtre">
          <b-form-input v-model="filterToUpdate.name"></b-form-input>
        </b-form-group>
        <div class="message">
          <div v-if="gettVisibilityProjectLoading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
          <div v-if="getVisibilityProjectError" class="error">
            <ul v-if="Array.isArray(getVisibilityProjectError)">
              <li v-for="(e, index) in getVisibilityProjectError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getVisibilityProjectError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('update_Filter')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import FilterProjet from '@/components/ui/FilterProjet'
export default {
  components: {
    FilterProjet
  },
  data() {
    return {
      initLoading: true,
      selectedUsers: null,
      selectedFilter: null,
      filterToDelete: null,
      filterToUpdate: null,
      error: null,
      name: null,
      type: null,
      conditions: {
        and: [],
        or: []
      }
    }
  },

  methods: {
    ...mapActions([
      'fetchFieldsToFilter',
      'fetchListeUsers',
      'fetchListFiltres',
      'addFilter',
      'deleteFilter',
      'updateFiltre',
      'addFilterUsers',
      'deleteFilterUsers',
      'resetErrorFilter',
      'addUsersToFilters',
      'fetchUsersInFilter',
      'fetchOperatorsToFields',
      'fetchvalueOfFields'
    ]),
    resetModal() {
      this.filterToDelete = null
      this.filterToUpdate = null
      this.error = null
      this.name = null
      this.type = null
      this.conditions = {
        and: [],
        or: []
      }
      this.resetErrorFilter()
    },
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },
    addNouveauFilter() {
      this.$refs['add_Filter'].show()
    },
    handleDeleteClick(deleted) {
      this.$refs['ModalDeleteFilter'].show()
      this.filterToDelete = deleted
    },
    handleUpdateClick(filter) {
      this.filterToUpdate = { ...filter }
      let conditionAnd = []
      let conditionOr = []
      if (this.filterToUpdate.conditions.and.length) {
        conditionAnd = this.filterToUpdate.conditions.and.map(and => {
          const field = this.getFieldsToFiltre.find(e => e.key == and.field_key)
          const operator = this.getOperatorToFiltre[
            and.field_key
          ].operators.find(e => e.key == and.operator)
          return {
            field_key: field,
            object: and.object.value,
            operator: operator,
            value: and.value
          }
        })
      }
      if (this.filterToUpdate.conditions.or.length) {
        conditionOr = this.filterToUpdate.conditions.or.map(or => {
          const field = this.getFieldsToFiltre.find(e => e.key == or.field_key)
          const operator = this.getOperatorToFiltre[
            or.field_key
          ].operators.find(e => e.key == or.operator)
          return {
            field_key: field,
            object: or.object.value,
            operator: operator,
            value: or.value
          }
        })
      }
      this.filterToUpdate.conditions = { and: conditionAnd, or: conditionOr }
      this.$refs['update_Filter'].show()
    },
    validateFilter(filter, condition) {
      const validate = {
        status: 'success',
        errors: []
      }
      for (let index = 0; index < condition.and.length; index++) {
        const element = condition.and[index]
        if (!element.field_key || !element.operator) {
          validate.status = 'error'
          validate.errors.push(
            'Tous les champs sont obligatoires pour la condition ET'
          )
        }
        if (
          element.operator.key != 'IS NULL' &&
          element.operator.key != 'IS NOT NULL' &&
          !element.value
        ) {
          validate.status = 'error'
          let msg = ''
          if (element.field_key && element.operator) {
            msg =
              'la valeur de champ de ' +
              element.field_key.key +
              ' est obligatoire pour la condition ET'
            validate.errors.push(msg)
          }
        }
      }
      for (let index = 0; index < condition.or.length; index++) {
        const element = condition.or[index]
        if (!element.field_key || !element.operator) {
          validate.status = 'error'
          validate.errors.push(
            'Tous les champs sont obligatoires pour la condition OU'
          )
        }
        if (
          element.operator.key != 'IS NULL' &&
          element.operator.key != 'IS NOT NULL' &&
          !element.value
        ) {
          validate.status = 'error'
          let msg = ''
          if (element.field_key && element.operator) {
            msg = msg =
              'la valeur de champ de ' +
              element.field_key.key +
              ' est obligatoire pour la condition OU'
            validate.errors.push(msg)
          }
        }
      }
      if (!filter.name) {
        validate.status = 'error'
        validate.errors.push('Le nom est obligatoire')
      }
      if (!filter.conditions.conditions.length) {
        validate.status = 'error'
        validate.errors.push('Ajouter au minimum une condition')
      }
      return validate
    },
    async ajouterFilter() {
      const conditions = []
      if (this.conditions.or.length) {
        conditions.push({
          glue: 'or',
          conditions: this.conditions.or.map(or => {
            let value = or.value ? or.value.id : null
            if (or.field_key.key == 'creation_date') {
              value = or.value ? or.value : null
            }
            return {
              field_key: or.field_key.key,
              object: or.object.value,
              operator: or.operator.key,
              value: value
            }
          })
        })
      }
      if (this.conditions.and.length) {
        conditions.push({
          glue: 'and',
          conditions: this.conditions.and.map(and => {
            let value = and.value ? and.value.id : null
            if (and.field_key.key == 'creation_date') {
              value = and.value ? and.value : null
            }
            return {
              field_key: and.field_key.key,
              object: and.object.value,
              operator: and.operator.key,
              value: value
            }
          })
        })
      }
      const payload = {
        name: this.name,
        type: 'project',
        is_for_visibility: 1,
        conditions: {
          glue: 'and',
          conditions: conditions
        }
      }
      const validate = this.validateFilter(payload, this.conditions)
      if (validate.status === 'error') {
        this.error = validate.errors
        return
      }
      const response = await this.addFilter(payload)
      this.error = null
      if (response) {
        this.hideModal('add_Filter')
      }
    },
    async handleDeleteClickFilter() {
      if (this.filterToDelete.conditions) {
        const response = await this.deleteFilter(this.filterToDelete)
        if (response) {
          this.hideModal('ModalDeleteFilter')
          this.selectedFilter = null
          this.fetchListeUsers({
            exclude: [
              'team',
              'technicianPipe',
              'visibleTeams',
              'commercialItePipe',
              'userPipe',
              'depot'
            ]
          })
        }
      } else {
        const response = await this.deleteFilterUsers({
          filter: this.selectedFilter,
          users: this.filterToDelete,
          exclude: [
            'visibilityFilter',
            'technicianPipe',
            'visibleTeams',
            'team'
          ]
        })
        if (response) {
          this.hideModal('ModalDeleteFilter')
          this.fetchListeUsers({
            exclude: [
              'team',
              'technicianPipe',
              'visibleTeams',
              'commercialItePipe',
              'userPipe',
              'depot'
            ]
          })
        }
      }
    },
    async handleUpdateClickFilter() {
      const conditions = []
      if (this.filterToUpdate.conditions.or.length) {
        conditions.push({
          glue: 'or',
          conditions: this.filterToUpdate.conditions.or.map(or => {
            let value = or.value ? or.value.id : null
            if (or.field_key.key == 'creation_date') {
              value = or.value ? or.value : null
            }
            return {
              field_key: or.field_key.key,
              object: or.object.value,
              operator: or.operator.key,
              value: value
            }
          })
        })
      }
      if (this.filterToUpdate.conditions.and.length) {
        conditions.push({
          glue: 'and',
          conditions: this.filterToUpdate.conditions.and.map(and => {
            let value = and.value ? and.value.id : null
            if (and.field_key.key == 'creation_date') {
              value = and.value ? and.value : null
            }
            return {
              field_key: and.field_key.key,
              object: and.object.value,
              operator: and.operator.key,
              value: value
            }
          })
        })
      }
      const payload = {
        id: this.filterToUpdate.id,
        name: this.filterToUpdate.name,
        type: 'project',
        conditions: {
          glue: 'and',
          conditions: conditions
        }
      }
      const validate = this.validateFilter(
        payload,
        this.filterToUpdate.conditions
      )
      if (validate.status === 'error') {
        this.error = validate.errors
        return
      }
      const response = await this.updateFiltre(payload)
      this.error = null
      if (response) {
        this.hideModal('update_Filter')
      }
    },
    handleClickfilter(filter) {
      this.selectedFilter = filter
      this.fetchUsersInFilter({
        filter,
        exclude: [
          'visibilityFilter.conditions',
          'visibilityFilter.users',
          'technicianPipe',
          'visibleTeams',
          'team',
          'userPipe',
          'depot',
          'technicianPipe'
        ]
      })
    },
    handleupdateFilterUsersClick(addUserfilter, users) {
      this.addFilterUsers({
        addUserfilter,
        users,
        filter: this.selectedFilter,
        exclude: [
          'team',
          'userPipe',
          'visibleTeams',
          'technicianPipe',
          'commercialItePipe',
          'depot'
        ]
      })
    },
    async handelClickUsersToFilter(users) {
      const response = await this.addUsersToFilters({
        filter: this.selectedFilter,
        users: users,
        exclude: [
          'conditions',
          'users.userPipe',
          'users.visibleTeams',
          'users.team',
          'users.technicianPipe',
          'users.depot'
        ]
      })
      if (response) {
        this.selectedUsers = []
        this.fetchListeUsers({
          exclude: [
            'team',
            'technicianPipe',
            'visibleTeams',
            'commercialItePipe',
            'userPipe',
            'depot'
          ]
        })
      }
    }
  },
  computed: {
    ...mapGetters([
      'getFieldsToFiltre',
      'getUsers',
      'getFiltersProject',
      'getVisibilityProjectError',
      'gettVisibilityProjectLoading',
      'getProjectUsersLoading',
      'getProjectDeleteUsersLoading',
      'getProjectChangeUsersLoading',
      'getProjectListUsersLoading',
      'getOperatorToFiltre',
      'getValueToFields'
    ]),
    computedFilter: function() {
      return this.getFiltersProject.filter(
        filter => filter.id != this.selectedFilter.id
      )
    }
  },
  async mounted() {
    await this.fetchFieldsToFilter({
      for_visibility: 1
    })
    if (this.getFieldsToFiltre) {
      for (let index = 0; index < this.getFieldsToFiltre.length; index++) {
        const element = this.getFieldsToFiltre[index]
        if (!this.getOperatorToFiltre[element.key]) {
          this.fetchOperatorsToFields({
            field_key: element.key
          })
        }
      }
    }
    this.initLoading = true
    await this.fetchListFiltres({
      exclude: 'conditions',
      for_visibility: 1
    })
    this.initLoading = false
    this.fetchListeUsers({
      exclude: [
        'team',
        'technicianPipe',
        'visibleTeams',
        'commercialItePipe',
        'userPipe',
        'depot'
      ]
    })
  }
}
</script>
<style lang="scss" scoped>
.visibility-project-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
  }
  .content-page {
    display: flex;
    flex-wrap: nowrap;
    .filter {
      border-right: 2px solid #eeeeee;
      max-width: 100%;
      .list-filter {
        .content-filter {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .name-filter {
            display: flex;
            .icon-check {
              color: #2dabe2;
              margin-right: 12px;
            }
            .capitalize {
              text-transform: capitalize;
            }
          }
        }
      }
      .not-filter {
        color: #2dabe2;
        margin-left: 30px;
        padding: 13px;
      }
    }
    .users {
      max-width: 100%;
      .bloc-select-multi {
        .postion-btn {
          display: flex;
          justify-content: flex-end;
          margin-top: 3px;
          .button-add-users {
            font-size: 11px;
          }
        }
      }
      .margin {
        margin: 12px;
      }
      .addListUsers {
        margin-top: 13px;
        .user {
          justify-content: space-between;
          display: flex;
          align-items: center;
          .email-user {
            color: #2dabe2;
            font-weight: normal;
            font-size: 12px;
          }
          .block-action {
            display: flex;
            align-items: center;
            .delete-user {
              cursor: pointer;
              color: #404346;
              font-weight: bold;
            }
            .dropDown-Users {
              margin-right: 42px;
              // margin-top: 5px;
              .capitalize {
                text-transform: capitalize;
              }
            }
          }
        }
        .message {
          text-align: center;
          margin-top: 10px;
          color: #2dabe2;
        }
      }
    }
  }
  @media screen and (max-width: 786px) {
    .header {
      white-space: unset;
    }
  }
  @media screen and (max-width: 580px) {
    .header {
      white-space: unset;
    }
    .content-page {
      flex-wrap: unset;
    }
    .row {
      flex-wrap: unset;
      display: block;
      .users {
        margin-top: 10px;
        .addListUsers {
          .user {
            display: flex;
            .block-action {
              .dropDown-Users {
                .btn-secondary {
                  font-size: 14px;
                  color: #404346;
                  background-color: #ffffff;
                  border-color: #404346;
                  & :hover {
                    font-size: 14px;
                    color: #404346;
                    background-color: #ffffff;
                    border-color: #404346;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
#ModalDeleteFilter {
  .capitalize {
    text-transform: capitalize;
  }
}
</style>
<style lang="scss">
.visibility-project-setting {
  .content-page {
    .filter {
      .content-filter {
        .buttons-filter {
          .dropDown-filter {
            .btn-link {
              font-weight: 400;
              color: #35495e;
              text-decoration: none;
            }
            .btn-lg,
            .btn-group-lg > .btn {
              font-size: 15px;
              line-height: 0;
              font-weight: bold;
              top: -2px;
              border-radius: 0.3rem;
            }
          }
        }
      }
    }
    .users {
      .addListUsers {
        .user {
          .block-action {
            .dropDown-Users {
              .btn-secondary {
                font-size: 14px;
                color: #404346;
                background-color: #ffffff;
                border-color: #404346;
                & :hover {
                  font-size: 14px;
                  color: #404346;
                  background-color: #ffffff;
                  border-color: #404346;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
