<template>
  <div class="filter-project">
    <b-form-group
      label="Afficher les projets correspondant à TOUTES ces conditions :"
    >
      <div v-if="localValue.and">
        <div
          class="list-element mb-2"
          v-for="(item, index) in localValue.and"
          :key="index"
        >
          <Multiselect
            class="value-type ml-2"
            v-model="item.object"
            :options="selectObject"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="text"
            track-by="value"
            :preselect-first="true"
            :allow-empty="false"
          >
            <span slot="noResult">Aucune option trouvée.</span>
            <span slot="noOptions">Aucune option trouvée.</span>
          </Multiselect>
          <Multiselect
            class="value-type ml-2"
            v-model="item.field_key"
            :options="getFieldsToFiltre"
            @input="changeKeyField(item.field_key, index, 'and')"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="name"
            track-by="key"
            :allow-empty="false"
          >
            <span slot="noResult">Aucune option trouvée.</span>
            <span slot="noOptions">Aucune option trouvée.</span>
          </Multiselect>
          <Multiselect
            class="value-type ml-2"
            v-model="item.operator"
            :options="selectedOperatorsAnd[index] || []"
            @input="changeOperator(item.operator)"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="label"
            track-by="key"
            :allow-empty="false"
            :loading="gettVisibilityProjectOperators"
          >
            <span slot="noResult">Aucun opérateur arithmétique trouvé.</span>
            <span slot="noOptions">Aucun opérateur arithmétique trouvé.</span>
          </Multiselect>
          <date-picker
            class="value-type ml-2"
            v-if="item.field_key.key == 'creation_date'"
            :disabled="item.isHidden"
            format="YYYY-MM-DD"
            v-model="item.value"
            value-type="format"
            type="date"
          ></date-picker>
          <Multiselect
            v-else
            :disabled="item.isHidden"
            class="value-type ml-2"
            v-model="item.value"
            @select="changeValue"
            :options="selectedValueAnd[index] || []"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="name"
            track-by="id"
            :allow-empty="false"
            :loading="gettVisibilityProjectOperators"
          >
            <span slot="noResult">Aucune option trouvée.</span>
            <span slot="noOptions">Aucune option trouvée.</span>
          </Multiselect>

          <div class="bolc-and">
            <div class="value-type ml-2">ET</div>
            <div class="action ml-2" @click="removecondition(index, 'and')">
              <font-awesome-icon class icon="trash-alt" />
            </div>
          </div>
        </div>
      </div>
      <div class="add-to-list-action" @click="addCondition('and')">
        <font-awesome-icon class="" icon="plus" /> Ajouter une condition
      </div>
    </b-form-group>
    <b-form-group label="Et correspond à une de ces conditions :">
      <div v-if="localValue.or">
        <div
          class="list-element mb-2"
          v-for="(item, index) in localValue.or"
          :key="index"
        >
          <Multiselect
            class="value-type ml-2"
            v-model="item.object"
            :options="selectObject"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="text"
            track-by="value"
            :preselect-first="true"
            :allow-empty="false"
          >
            <span slot="noResult">Aucune option trouvée.</span>
            <span slot="noOptions">Aucune option trouvée.</span>
          </Multiselect>
          <Multiselect
            class="value-type ml-2"
            v-model="item.field_key"
            :options="getFieldsToFiltre"
            @input="changeKeyField(item.field_key, index, 'or')"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="name"
            track-by="key"
            :allow-empty="false"
          >
            <span slot="noResult">Aucune option trouvée.</span>
            <span slot="noOptions">Aucune option trouvée.</span>
          </Multiselect>
          <Multiselect
            class="value-type ml-2"
            v-model="item.operator"
            :options="selectedOperatorsOr[index] || []"
            @input="changeOperator(item.operator)"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="label"
            track-by="key"
            :allow-empty="false"
            :loading="gettVisibilityProjectOperators"
          >
            <span slot="noResult">Aucun opérateur arithmétique trouvé.</span>
            <span slot="noOptions">Aucun opérateur arithmétique trouvé.</span>
          </Multiselect>
          <date-picker
            class="value-type ml-2"
            v-if="item.field_key.key == 'creation_date'"
            :disabled="item.isHidden"
            format="YYYY-MM-DD"
            v-model="item.value"
            value-type="format"
            type="date"
          ></date-picker>
          <Multiselect
            v-else
            :disabled="item.isHidden"
            class="value-type ml-2"
            v-model="item.value"
            @select="changeValue"
            :options="selectedValueOr[index] || []"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="name"
            track-by="id"
            :allow-empty="false"
            :loading="gettVisibilityProjectOperators"
          >
            <span slot="noResult">Aucune option trouvée.</span>
            <span slot="noOptions">Aucune option trouvée.</span>
          </Multiselect>
          <div class="bloc-or">
            <div class="value-type ml-2">OU</div>
            <div class="action ml-2" @click="removecondition(index, 'or')">
              <font-awesome-icon class icon="trash-alt" />
            </div>
          </div>
        </div>
      </div>
      <div class="add-to-list-action" @click="addCondition('or')">
        <font-awesome-icon class="" icon="plus" /> Ajouter une condition
      </div>
    </b-form-group>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
export default {
  components: {
    Multiselect,
    DatePicker
  },
  props: {
    fields: { required: true },
    value: {
      default: () => {
        return {
          conditions: {
            and: [],
            or: []
          }
        }
      }
    }
  },
  data() {
    return {
      localValue: { ...this.value },
      selectedOperatorsAnd: [],
      selectedOperatorsLinkAnd: [],
      selectedValueAnd: [],
      selectedOperatorsOr: [],
      selectedOperatorsLinkOr: [],
      selectedValueOr: [],
      selectObject: [{ value: 'project', text: 'Project' }],
      changeFieldkey: {
        index: null,
        condition: null
      }
    }
  },

  methods: {
    ...mapActions([
      'fetchOperatorsToFields',
      'fetchvalueOfFields',
      'fetchFieldsToFilter'
    ]),
    changeKeyField(keyField, index, condition) {
      if (!this.getOperatorToFiltre[keyField.key]) {
        this.fetchOperatorsToFields({
          field_key: keyField.key
        })
      }
      this.changeFieldkey = {
        index: index,
        condition: condition
      }
      if (this.localValue && this.localValue.and.length) {
        this.selectedOperatorsAnd = []
        this.selectedOperatorsLinkAnd = []
        this.selectedValueAnd = []
        this.appelValue()
      }
      if (this.localValue && this.localValue.or.length) {
        this.selectedOperatorsOr = []
        this.selectedOperatorsLinkOr = []
        this.selectedValueOr = []
        this.appelValue()
      }
    },
    changeOperator() {
      this.localValue.and.map(and => {
        and.isHidden = false
        if (
          and.operator.key == 'IS NULL' ||
          and.operator.key == 'IS NOT NULL'
        ) {
          and.isHidden = true
          and.value = null
        }
      })
      this.localValue.or.map(or => {
        or.isHidden = false
        if (or.operator.key == 'IS NULL' || or.operator.key == 'IS NOT NULL') {
          or.isHidden = true
          or.value = null
        }
      })
    },
    addCondition(etatFiltre) {
      if (etatFiltre == 'and') {
        this.localValue.and.push({
          object: { value: 'project', text: 'Project' },
          field_key: '',
          operator: '',
          value: '',
          isHidden: false
        })
        for (let index = 0; index < this.localValue.and.length; index++) {
          const keyField = this.localValue.and[index]
          if (!keyField.field_key) {
            this.selectedOperatorsAnd[index] = []
            this.selectedOperatorsLinkAnd[index] = []
            this.selectedValueAnd[index] = []
          }
        }
      }
      if (etatFiltre == 'or') {
        this.localValue.or.push({
          object: { value: 'project', text: 'Project' },
          field_key: '',
          operator: '',
          value: '',
          isHidden: false
        })
        for (let index = 0; index < this.localValue.or.length; index++) {
          const keyField = this.localValue.or[index]
          if (!keyField.field_key) {
            this.selectedOperatorsOr[index] = []
            this.selectedOperatorsLinkOr[index] = []
            this.selectedValueOr[index] = []
          }
        }
      }
    },
    removecondition(index, etatFiltre) {
      if (etatFiltre == 'and') {
        this.localValue.and.splice(index, 1)
      } else {
        this.localValue.or.splice(index, 1)
      }
      this.changeValue()
    },
    changeValue() {
      this.$emit('input', this.localValue)
      this.$emit('change', this.localValue)
    },
    async appelValue() {
      if (this.localValue && this.localValue.and) {
        for (let index = 0; index < this.localValue.and.length; index++) {
          const keyField = this.localValue.and[index]
          if (
            this.changeFieldkey.index == index &&
            this.changeFieldkey.condition === 'and' &&
            (keyField.value || keyField.operator)
          ) {
            keyField.operator = ''
            keyField.value = ''
          }
          keyField.isHidden = false
          if (
            keyField.operator.key == 'IS NULL' ||
            keyField.operator.key == 'IS NOT NULL'
          ) {
            keyField.isHidden = true
            keyField.value = null
          }
          if (keyField.field_key) {
            this.selectedOperatorsAnd[index] = this.getOperatorToFiltre[
              keyField.field_key.key
            ].operators

            this.selectedOperatorsLinkAnd[index] = this.getOperatorToFiltre[
              keyField.field_key.key
            ].links
            if (
              this.selectedOperatorsLinkAnd[index] &&
              this.selectedOperatorsLinkAnd[index].length
            ) {
              for (
                let indexLink = 0;
                indexLink < this.selectedOperatorsLinkAnd[index].length;
                indexLink++
              ) {
                const link = this.selectedOperatorsLinkAnd[index][indexLink]
                  .value
                // if (
                //   link == '/api/coms-sed' ||
                //   link == '/api/csubstages-ids-names-status'
                // ) {
                await this.fetchvalueOfFields(link)
                // }
              }
            } else {
              await this.fetchvalueOfFields(
                this.selectedOperatorsLinkAnd[index]
              )
            }
            if (this.getValueToFields) {
              if (keyField.field_key.key == 'department') {
                this.selectedValueAnd[index] = this.getValueToFields.map(
                  value => {
                    return {
                      name: value.department,
                      id: value.id
                    }
                  }
                )
              } else if (keyField.field_key.key == 'comm_sed') {
                this.selectedValueAnd[index] = this.getValueToFields.map(
                  value => {
                    return {
                      name: value.full_name,
                      id: value.id
                    }
                  }
                )
              } else {
                this.selectedValueAnd[index] = this.getValueToFields
              }
            }
          }
        }
      }
      if (this.localValue && this.localValue.or) {
        for (let index = 0; index < this.localValue.or.length; index++) {
          const keyField = this.localValue.or[index]
          if (
            this.changeFieldkey.index == index &&
            this.changeFieldkey.condition === 'or' &&
            (keyField.value || keyField.operator)
          ) {
            keyField.operator = ''
            keyField.value = ''
          }
          keyField.isHidden = false
          if (
            keyField.operator.key == 'IS NULL' ||
            keyField.operator.key == 'IS NOT NULL'
          ) {
            keyField.isHidden = true
            keyField.value = null
          }
          if (keyField.field_key) {
            this.selectedOperatorsOr[index] = this.getOperatorToFiltre[
              keyField.field_key.key
            ].operators
            this.selectedOperatorsLinkOr[index] = this.getOperatorToFiltre[
              keyField.field_key.key
            ].links
            if (
              this.selectedOperatorsLinkOr[index] &&
              this.selectedOperatorsLinkOr[index].length
            ) {
              for (
                let indexLink = 0;
                indexLink < this.selectedOperatorsLinkOr[index].length;
                indexLink++
              ) {
                const link = this.selectedOperatorsLinkOr[index][indexLink]
                  .value
                // if (
                //   link == '/api/coms-sed' ||
                //   link == '/api/csubstages-ids-names-status'
                // ) {
                await this.fetchvalueOfFields(link)
                // }
              }
            } else {
              await this.fetchvalueOfFields(this.selectedOperatorsLinkOr[index])
            }
            if (this.getValueToFields) {
              if (keyField.field_key.key == 'department') {
                this.selectedValueOr[index] = this.getValueToFields.map(
                  value => {
                    return {
                      name: value.department,
                      id: value.id
                    }
                  }
                )
              } else if (keyField.field_key.key == 'comm_sed') {
                this.selectedValueOr[index] = this.getValueToFields.map(
                  value => {
                    return {
                      name: value.full_name,
                      id: value.id
                    }
                  }
                )
              } else {
                this.selectedValueOr[index] = this.getValueToFields
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getFieldsToFiltre',
      'getOperatorToFiltre',
      'getValueToFields',
      'gettVisibilityProjectOperators'
    ])
  },
  mounted() {
    this.appelValue().then(() => {
      if (this.localValue && this.localValue.and) {
        for (let index = 0; index < this.localValue.and.length; index++) {
          const keyField = this.localValue.and[index]
          if (keyField.field_key.key != 'creation_date') {
            const value = this.selectedValueAnd[index].find(
              e => e.id == keyField.value
            )
            keyField.value = value
          }
        }
      }
      if (this.localValue && this.localValue.or) {
        for (let index = 0; index < this.localValue.or.length; index++) {
          const keyField = this.localValue.or[index]
          if (keyField.field_key.key != 'creation_date') {
            const value = this.selectedValueOr[index].find(
              e => e.id == keyField.value
            )
            keyField.value = value
          }
        }
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.filter-project {
  .add-to-list-action {
    color: #2dabe2;
    cursor: pointer;
    width: 22%;
    &.disabled {
      color: #ced4da;
      cursor: default;
    }
  }
  .list-element {
    display: flex;
    align-items: center;
    .value-type {
      flex-shrink: 3;
    }
    .bolc-and,
    .bloc-or {
      display: flex;
    }
    .action {
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 990px) {
  .filter-project {
    .add-to-list-action {
      width: 40%;
    }
    .list-element {
      display: initial;
      .value-type {
        margin-bottom: 5px;
        max-width: initial;
      }
      .bolc-and,
      .bloc-or {
        display: flex;
        justify-content: flex-end;
      }
      .action {
        margin-left: 0px;
      }
    }
  }
}
</style>
<style lang="scss">
.mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
  .mx-input {
    display: inline-block;
    width: 100%;
    height: 42px;
    padding: 6px 30px;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    color: #35495e;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
  }
}
.filter-project {
  .list-element {
    .multiselect--disabled {
      background: #ededed;
      pointer-events: none;
      border-radius: 5px;
      opacity: 2.6;
    }
    .value-type {
      .multiselect__select {
        height: 40px;
      }
    }
  }
}
</style>
